import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import Seo from '../components/Seo';
import { pressHolder } from './press.module.scss';
import { motion } from 'framer-motion';

const articleVariants = {
  initial: {
    opacity: 0,
    x: -100,
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
      type: 'tween',
    },
  },
  exit: {
    opacity: 0,
    x: 100,
    transition: {
      duration: 0.5,
      type: 'tween',
    },
  },
};

const holderVariants = {
  initial: {},
  animate: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  exit: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

export default function PressPage({
  data: {
    allWpPressArticle: { nodes: articles },
  },
}) {
  return (
    <article className={pressHolder}>
      <h2>Press</h2>
      {articles.length > 0 ? (
        <motion.ul variants={holderVariants} className="articles">
          {articles.map((article) => {
            const image = getImage(article.pressInformation.image.localFile);
            return (
              <motion.li key={article.id} className="lrHolder article" variants={articleVariants}>
                <a href={article.pressInformation.link} title={'Go to this article'} target="_blank" rel="noreferrer" className="image">
                  <GatsbyImage image={image} alt={article.title} className="lhs " />
                </a>
                <div className="articleInformation rhs">
                  <div className="preTitle">
                    {article.pressInformation.date && <span>{article.pressInformation.date}</span>}
                    <span>{`${article.pressInformation.date && article.pressInformation.publication ? ' - ' : ''}`}</span>
                    {article.pressInformation.publication && <span>{article.pressInformation.publication}</span>}
                  </div>
                  <h3>{article.title}</h3>
                  <div className="about" dangerouslySetInnerHTML={{ __html: article.pressInformation.about }}></div>
                  <a href={article.pressInformation.link} title={'Go to this article'} target="_blank" rel="noreferrer" className="link">
                    Read Article
                  </a>
                </div>
              </motion.li>
            );
          })}
        </motion.ul>
      ) : (
        <p>There are no press articles to display.</p>
      )}

      <svg width="273" height="8" viewBox="0 0 273 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="37.4244" cy="3.91587" r="3.91616" transform="rotate(-90 37.4244 3.91587)" fill="black" />
        <circle cx="3.91616" cy="3.91587" r="3.91616" transform="rotate(-90 3.91616 3.91587)" fill="black" />
        <circle cx="169.7" cy="3.91587" r="3.91616" transform="rotate(-90 169.7 3.91587)" fill="black" />
        <circle cx="70.4938" cy="3.91587" r="3.91616" transform="rotate(-90 70.4938 3.91587)" fill="black" />
        <circle cx="202.771" cy="3.91587" r="3.91616" transform="rotate(-90 202.771 3.91587)" fill="black" />
        <circle cx="103.562" cy="3.91587" r="3.91616" transform="rotate(-90 103.562 3.91587)" fill="black" />
        <circle cx="235.839" cy="3.91587" r="3.91616" transform="rotate(-90 235.839 3.91587)" fill="black" />
        <circle cx="136.633" cy="3.91587" r="3.91616" transform="rotate(-90 136.633 3.91587)" fill="black" />
        <circle cx="268.91" cy="3.91587" r="3.91616" transform="rotate(-90 268.91 3.91587)" fill="black" />
      </svg>
    </article>
  );
}

//useStaticQuery to query for allWpPress

export const data = graphql`
  query PressPage {
    allWpPressArticle(sort: { fields: pressInformation___date, order: DESC }) {
      nodes {
        title
        id
        pressInformation {
          about
          date(formatString: "DD/MM/YY")
          link
          publication
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 586, height: 450, placeholder: BLURRED, formats: AUTO)
              }
            }
          }
        }
      }
    }
  }
`;

export const Head = () => <Seo title="Press" />;
